import PropTypes from "prop-types";
import { paramCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link, Stack, Typography } from "@mui/material";
// routes
import { PATHS } from "../../../../routes/paths";
// utils
// components
import Image from "../../../../components/image/Image";
import { useLocales } from "../../../../locales";
import ReactGA from "react-ga4";
import { IMAGES_CONSTANTS } from "../../../../config/constants/images-constants";
import { useEffect, useState } from "react";
import useResponsive from "../../../../hooks/useResponsive";
import { LazyLoadImage } from "react-lazy-load-image-component";

// ----------------------------------------------------------------------

ShopEventCard.propTypes = {
  price: PropTypes.number,
  id: PropTypes.string,
  name: PropTypes.string,
  cover: PropTypes.string,
  subcategory: PropTypes.string,
  sx: PropTypes.object,
};

export default function ShopEventCard({
  name,
  price,
  cover,
  id,
  subcategory,
  entryType,
  sx,
}) {
  const isSmallScreen = useResponsive("down", "md");
  const isAboveLg = useResponsive("up", "lg");
  const isBetweenSmAndMd = useResponsive("between", "sm", "md");

  const linkTo = PATHS.listings.view(
    paramCase(subcategory),
    paramCase(name),
    paramCase(id)
  );
  const { translate } = useLocales();
  const [imageHasApiCompression, setImageHasApiCompression] = useState(true);
  const [coverImageSize, setCoverImageSize] = useState(
    IMAGES_CONSTANTS.CAROUSEL_SIZE_MOBILE
  );

  useEffect(() => {
    if (isBetweenSmAndMd || isAboveLg) {
      setCoverImageSize(IMAGES_CONSTANTS.SIZES.MEDIUM);
    }
  }, [isBetweenSmAndMd, isAboveLg]);

  const handleClick = () => {
    try {
      ReactGA.event("select_item", {
        items: [
          {
            item_id: id,
            item_name: name,
            item_category: "event",
            price: price,
          },
        ],
      });
    } catch (e) { }
  };

  return (
    <Link
      color="inherit"
      to={linkTo}
      component={RouterLink}
      onClick={handleClick}
    >
      <Stack sx={sx || {}} direction="column">
        <Box sx={{ position: "relative" }}>
          <Image
            alt={name}
            imageComponent={LazyLoadImage}
            key={imageHasApiCompression ? `${cover}@${coverImageSize}` : cover}
            src={imageHasApiCompression ? `${cover}@${coverImageSize}` : cover}
            ratio={IMAGES_CONSTANTS.LISTING_IMAGE_RATIO}
            sx={{ borderRadius: 1 }}
            onError={() => {
              setImageHasApiCompression(false);
            }}
          />
        </Box>

        {!isSmallScreen && (
          <Stack spacing={0} sx={{ pt: 1 }}>
            <Typography variant="h6">{name}</Typography>

            {price !== null ? (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {entryType === "TICKET" ? (
                  <Typography fontSize={14} color="gray">{`${translate(
                    "listingList.startingFrom"
                  )} ${price} lei`}</Typography>
                ) : (
                  <Typography fontSize={14} color="gray">
                    {translate("listingList.freeEntry")}
                  </Typography>
                )}
              </Stack>
            ) : null}
          </Stack>
        )}
      </Stack>
    </Link>
  );
}
