import { useAppSettings } from "./useAppSettings";
import { useAuthContext } from "./useAuthContext";

export const useIsMaintenance = () => {
    const { user } = useAuthContext();
    const { data,refetch,loading } = useAppSettings();
    const isWebMaintenance = data?.getSettings?.maintenanceWeb;
    const isAdmin = user?.role === "admin";
    
    return{isWebMaintenance: !isAdmin && isWebMaintenance,refetch,loading};
}