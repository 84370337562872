import { gql } from "@apollo/client";

const GET_COMPANIES = gql(`
query Companies {
    companies {
      id
      firstName
      lastName
      avatar
      comission
      companyInfo {
        id
        displayName
        registrationCountry
        companyName
        companyType
        uniqueRegistrationCode
        euVatNumber
        tradeRegistryNumber
        europeanUniqueIdentifier
        vatObligations
        county
        city
        address {
          street
          city
          county
          venueName
          googleLocationLink
          number
        }
        postalCode
        legalRepresentativeFirstName
        legalRepresentativeLastName
        legalRepresentativeEmail
        legalRepresentativePhone
        iban
        bankName
        swiftCode
        currency
        documents {
          title
          url
        }
      }
      approval
      account {
        email
        phone
      }
    }
  }
`);

const GET_COMPANY = gql(`
query Company($companyId: ID!) {
  company(id: $companyId) {
    id
    firstName
    lastName
    avatar
    comission
    invoiceSeries
    invoiceNumber
    companyInfo {
      id
      displayName
      registrationCountry
      companyName
      companyType
      uniqueRegistrationCode
      euVatNumber
      tradeRegistryNumber
      europeanUniqueIdentifier
      vatObligations
      county
      city
      address {
      number
        street
        city
        county
        venueName
        googleLocationLink
      }
      postalCode
      legalRepresentativeFirstName
      legalRepresentativeLastName
      legalRepresentativeEmail
      legalRepresentativePhone
      iban
      bankName
      swiftCode
      currency
      paymentProviderName
      paysVat
      documents {
        title
        url
      }
    }
    approval
  }
}
`);

export { GET_COMPANIES, GET_COMPANY };
