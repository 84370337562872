import { useQuery } from "@apollo/client";
import { ADMIN_PLATFORM_SETTINGS_CONSTANTS } from "src/config/constants/admin-platform-settings-constants"
import { GET_SETTINGS }  from "src/graphql/queries/settings"

export const useAppSettings = () => {
    return useQuery(GET_SETTINGS, {
        variables: {
          getSettingsId: ADMIN_PLATFORM_SETTINGS_CONSTANTS.SETTINGS_ID,
        },
        fetchPolicy:'network-only'
      });
}
