import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
// @mui
import {
  Alert,
  Box,
  Card,
  CardContent,
  Chip,
  List,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../../../../components/iconify";
import { useLocales } from "../../../../locales";
import { useNavigate } from "react-router-dom";
import Markdown from "../../../../components/Markdown";
//utils
import { fCurrency } from "../../../../utils/formatNumber";
import { PATHS } from "../../../../routes/paths";
import {
  PrimaryButton,
  SecondaryButton,
} from "../listing-details/listingButtons";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import getRoles from "../../../../utils/getRoles";
import { useSnackbar } from "notistack";
import { TICKET_CONSTANTS } from "../../../../config/constants/ticket-constants";
import useResponsive from "../../../../hooks/useResponsive";
import { useMutationWithAuth } from "src/graphql/instances";
import { CLEAR_CART_ITEMS, VALIDATE_CART } from "src/graphql/mutations";
import { GET_CART, GET_CART_QUANTITY } from "src/graphql/queries";
import { useApolloClient, useLazyQuery } from "@apollo/client";
import DeleteForeignTicketsModal from "../listing-details/DeleteForeignTicketsModal";
import { Incrementer } from "../shop";
import Image from "../../../../components/image";
import { SeatsChart } from "src/components/SeatsChart";

export const getTicketCard = (
  theme,
  translate,
  shouldRenderButtons,
  listing,
  initialTicketQuantities,
  updateTicketQuantities,
  addToCart,
  chartKey
) => {
  if (listing?.tickets?.entryType === "FREE") {
    return (
      <Card
        variant=""
        raised
        sx={{
          mt: 2,
          mb: 5,
          py: 3,
          backgroundColor: theme.palette.grey[800],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px dashed grey",
          borderRadius: "8px",
        }}
      >
        <CardContent
          sx={{
            pb: 0,
            pt: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }} align="center">
            {translate("productPage.freeAccess")}
          </Typography>
          <Typography variant="subtitle" color="grey">
            {translate("productPage.noTicket")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (!sortTickets([...listing?.tickets?.ticketTypes]).length) {
    return (
      <Card
        raised
        sx={{
          mt: 2,
          mb: 5,
          py: 3,
          backgroundColor: theme.palette.grey[800],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "1px dashed grey",
          borderRadius: "8px",
        }}
      >
        <CardContent
          sx={{
            pb: 0,
            pt: 0,
            "&:last-child": {
              paddingBottom: 0,
            },
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }} align="center">
            {translate("productPage.noPublishedTickets")}
          </Typography>
          <Typography variant="h5" color="grey">
            {translate("productPage.comeBack")}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <TicketShopCard
      eventEndDate={new Date(listing.general.endDate)}
      ticketTypes={sortTickets([...listing?.tickets?.ticketTypes])}
      onAddCart={addToCart}
      theme={theme}
      eventHasSeatingMap={!!chartKey}
      eventChartKey={chartKey}
      eventId={listing?.general?.id}
      shouldRenderButtons={shouldRenderButtons}
      initialTicketQuantities={initialTicketQuantities}
      updateTicketQuantities={updateTicketQuantities}
    />
  );
};

const sortTickets = (ticketList) =>
  ticketList
    .sort((a, b) => {
      let order;
      if (a.promotionType || b.promotionType) {
        order = (b.promotionType || "z").localeCompare(a.promotionType || "z");
      }
      return order || a.ticketOrder - b.ticketOrder;
    })
    .filter((ticket) => ticket.isPublished);

const Step1Flow = ({
  eventChartKey,
  seatingMapPricing,
  setChart,
  maxSelectableSeats,
  setUserSelectedAllSeats,
}) => {
  return (
    <List
      sx={{
        maxHeight: "auto",
        borderRadius: "8px",
      }}
    >
      <SeatsChart
        eventChartKey={eventChartKey}
        maxSelectableSeats={maxSelectableSeats}
        setUserSelectedAllSeats={setUserSelectedAllSeats}
        onChartRendered={(chart) => {
          setChart(chart);
        }}
        seatingMapPricing={seatingMapPricing}
      />
    </List>
  );
};

TicketShopCard.propTypes = {
  ticketTypes: PropTypes.array,
  onAddCart: PropTypes.func,
  eventEndDate: PropTypes.object,
  theme: PropTypes.object,
};

const MOBILE_BUY_BUTTON_PADDING = "6px";
const WEB_BUY_BUTTON_PADDING = "17px";
const MOBILE_BUY_BUTTON_WIDTH = "153px";
const WEB_BUY_BUTTON_WIDTH = "165px";

export default function TicketShopCard({
  ticketTypes,
  onAddCart,
  eventEndDate,
  theme,
  eventHasSeatingMap,
  eventChartKey,
  eventId,
  shouldRenderButtons,
  initialTicketQuantities,
  updateTicketQuantities,
}) {
  const [totalItems, setTotalItems] = useState(0);
  // This state handles the step of the seating map flow, 0 = not started, 1 = started,
  //  can only be 1 if the event has a seating map
  const [ticketFlowStep, setTicketFlowStep] = useState(0);
  const [chart, setChart] = useState(null);
  const [maxSelectableSeats, setMaxSelectableSeats] = useState([]);
  const [userSelectedAllSeats, setUserSelectedAllSeats] = useState(false);

  const { translate } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useResponsive("down", "sm");
  const [cloudQuantity, setCloudQuantity] = useState([]);
  const [localQuantity, setLocalQuantity] = useState(
    initialTicketQuantities || []
  );
  const [showAlternativeButtonText, setShowAlternativeButtonText] =
    useState(false);
  const [hasOtherEventItemsInCart, setHasOtherEventItemsInCart] =
    useState(false);
  const [clearCartModalOpen, setClearCartModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);

  const { user } = useAuthContext();
  const [validateCart] = useMutationWithAuth(VALIDATE_CART);
  const [clearCartItems] = useMutationWithAuth(CLEAR_CART_ITEMS);
  const accessToken = localStorage.getItem("accessToken");

  const [getCart] = useLazyQuery(GET_CART, {
    context: { headers: { Authorization: accessToken } },
    fetchPolicy: "no-cache", // Doesn't check cache before making a network request
  });
  const client = useApolloClient();

  const getCartData = async () => {
    if (getRoles().People.includes(user.role)) {
      try {
        try {
          await validateCart();
        } catch (e) {}
        const cartResponse = await getCart();

        const newLocalQuantity = [];
        const newCloudQuantity = [];

        const apiCartItems = cartResponse?.data?.cart?.items;

        let latestTotalItems = 0;

        if (apiCartItems.length > 0) {
          cartResponse?.data?.cart?.items?.forEach((item) => {
            const isTicketInTicketTypes = ticketTypes.find(
              (ticketType) => ticketType.id === item?.ticketType?.id
            );

            if (item?.ticketType) {
              latestTotalItems += item.quantity;
            }

            if (isTicketInTicketTypes) {
              newLocalQuantity.push({
                id: item?.ticketType?.id,
                quantity: item.quantity || 0,
                price: item?.ticketType?.price || 0,
                seatIds: item?.seatIds,
                seatsHoldToken: item?.seatsHoldToken,
                name: item?.ticketType?.name,
              });
              newCloudQuantity.push({
                quantity: item.quantity || 0,
                id: item.id,
                ticketId: item?.ticketType?.id,
                seatIds: item?.seatIds,
                seatsHoldToken: item?.seatsHoldToken,
              });
            }
          });

          setTotalItems(latestTotalItems);
          setCloudQuantity(newCloudQuantity);
          setLocalQuantity(newLocalQuantity);

          checkClearCartModalStatus(apiCartItems);
        } else {
          setTotalItems(0);
        }

        if (Object.keys(newLocalQuantity).length !== 0) {
          setShowAlternativeButtonText(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getCartData();
    };

    if (!initialTicketQuantities) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (updateTicketQuantities) updateTicketQuantities(localQuantity);
  }, [cloudQuantity, localQuantity]);

  const checkClearCartModalStatus = (apiCartItems) => {
    const itemsToOtherEvent = apiCartItems?.filter(
      (it) =>
        (it.ticketType && it.ticketType?.eventId !== eventId) ||
        (it.bookingModule && it.bookingModule.event.general.id !== eventId)
    );

    setHasOtherEventItemsInCart(!!itemsToOtherEvent?.length);
  };

  const doClearCartItems = async () => {
    await clearCartItems();

    client.refetchQueries({
      include: [GET_CART_QUANTITY],
    });
    getCartData(false);
  };

  const seatingMapPricing = ticketTypes?.map((ticket) => {
    if (!ticket?.isPublished) {
      return null;
    }

    return {
      category: ticket.name,
      price: ticket.price,
    };
  });

  const isTicketAvailable = (ticket) => {
    return Boolean(ticket.available);
  };

  const decrementQuantity = (id) => {
    const updatedQuantities = localQuantity
      .map((item) => {
        if (item.id === id) {
          item.quantity--;
          if (item.quantity === 0) {
            return null;
          }
        }
        return item;
      })
      .filter(Boolean);

    setLocalQuantity(updatedQuantities);
  };

  const incrementQuantity = (ticket) => {
    const existingItem = localQuantity.find((item) => item.id === ticket.id);

    if (existingItem) {
      const updatedQuantities = localQuantity.map((item) => {
        if (item.id === ticket.id) {
          return { ...item, quantity: item.quantity + 1 };
        }
        return item;
      });
      setLocalQuantity(updatedQuantities);
    } else {
      setLocalQuantity([
        ...localQuantity,
        {
          id: ticket.id,
          price: ticket.price,
          quantity: 1,
          name: ticket.name,
          seatIds: [],
          seatsHoldToken: ticket.seatsHoldToken,
        },
      ]);
    }
  };

  const getTotalQty = () =>
    localQuantity.reduce((total, item) => total + item.quantity, 0);

  const getTotalCloudQty = () =>
    cloudQuantity.reduce((total, item) => total + item.quantity, 0);

  const isOtherEarlyTicket = (ticket) => {
    const earlybirds = ticketTypes
      .filter((t) => t.category.name === "Earlybird")
      .filter((t) => !!t.available);
    if (!earlybirds[0]) {
      return false;
    }

    return earlybirds[0].id !== ticket.id;
  };

  const getIncrementer = (ticket) => {
    if (!ticket.available && eventEndDate >= new Date()) {
      return <Chip label={translate("productPage.soldOut")} />;
    }

    if (ticket.category.name === "Earlybird" && isOtherEarlyTicket(ticket)) {
      return <Chip color="light" label={translate("productPage.comingSoon")} />;
    }

    if (isTicketAvailable(ticket))
      return (
        <Incrementer
          available={ticket.available}
          totalItems={totalItems}
          totalQty={getTotalQty()}
          quantity={
            localQuantity.find((item) => item.id === ticket.id)?.quantity || 0
          }
          onDecrementQuantity={() => {
            decrementQuantity(ticket.id);
          }}
          onIncrementQuantity={() => {
            incrementQuantity(ticket);
          }}
          initialQuantity={getTotalCloudQty()}
          maxQuantity={TICKET_CONSTANTS.MAX_CART_QUANTITY}
        />
      );
  };

  const isPastMaxValue = useMemo(() => {
    return (
      totalItems - getTotalCloudQty() + getTotalQty() >=
      TICKET_CONSTANTS.MAX_CART_QUANTITY
    );
  }, [totalItems, localQuantity, cloudQuantity]);

  const getNumberOfTicketsForSeatingMap = () => {
    const maxSelectableObjects = localQuantity.map(({ id, quantity }) => {
      const ticket = ticketTypes.find((t) => t.id === id);
      return {
        category: ticket.name,
        quantity: quantity,
      };
    });

    setMaxSelectableSeats(maxSelectableObjects);
  };

  const getMinimumPromotionPrice = (ticketTypes) => {
    const tieredTickets = ticketTypes.filter(
      (ticket) => ticket.promotionType === "TIERED"
    );
    const allPrices = tieredTickets.flatMap((ticket) =>
      ticket.promotionMetadata.map((promo) => promo.price)
    );
    if (allPrices.length === 0) return null;
    return Math.min(...allPrices);
  };
  const minPrice = getMinimumPromotionPrice(ticketTypes);

  const Step0Flow = () => {
    return (
      <>
        {ticketTypes.map(
          (ticket) =>
            (ticket.isPublished &&
              (!ticket.promotionType ||
                ticket.promotionType === "NO_PROMOTION") && (
                <Card
                  key={ticket.id}
                  raised
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.grey[800],
                    borderRadius: "8px",
                  }}
                >
                  <CardContent
                    sx={{
                      p: 2,
                      pb: "0 !important",
                      display: "flex",
                      justifyContent: "space-between",
                      minHeight: 100,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          ...(!isTicketAvailable(ticket) && {
                            textDecoration: "line-through",
                          }),
                        }}
                      >
                        {ticket.name}
                      </Typography>
                      <Markdown children={ticket.description} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        align="right"
                        style={{
                          paddingRight: 6,
                          marginBottom: 6,
                          ...(!isTicketAvailable(ticket) && {
                            textDecoration: "line-through",
                          }),
                        }}
                        component={"div"}
                      >
                        {`${fCurrency(ticket.price)} lei`}
                      </Typography>
                      {getIncrementer(ticket)}
                    </div>
                  </CardContent>
                </Card>
              )) ||
            (ticket.isPublished &&
              ticket.promotionType &&
              ticket.promotionType !== "NO_PROMOTION" && (
                <Card
                  key={ticket.id}
                  raised
                  sx={{
                    mt: 2,
                    backgroundColor: theme.palette.grey[800],
                    borderRadius: "8px",
                  }}
                >
                  <CardContent
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      minHeight: 100,
                    }}
                  >
                    <Box style={styles.rowBox}>
                      <Box style={{}}>
                        <Typography
                          style={{
                            fontSize: 12,
                            color: theme.palette.primary.main,
                          }}
                        >
                          {translate("productPage.onlyinApp")}
                        </Typography>
                        <Typography
                          style={{
                            marginTop: 5,
                            color: theme.palette.text.primary,
                          }}
                        >
                          {ticket?.name}
                        </Typography>
                      </Box>
                      <Box style={{}}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                          }}
                        >
                          {ticket.promotionType === "ONLY_IN_APP" && (
                            <Typography
                              variant="subtitle1"
                              align="right"
                              style={{
                                paddingRight: 6,
                                marginBottom: 6,
                                textDecoration: "line-through",
                                color: theme.palette.grey[500],
                              }}
                              component={"div"}
                            >
                              {`${fCurrency(
                                ticket.promotionMetadata.normalPrice
                              )} lei`}
                            </Typography>
                          )}
                          <Typography
                            variant="subtitle1"
                            align="right"
                            style={{
                              paddingRight: 6,
                              marginBottom: 6,
                              ...(!isTicketAvailable(ticket) && {
                                textDecoration: "line-through",
                              }),
                            }}
                            component={"div"}
                          ></Typography>
                        </Box>
                        <PrimaryButton
                          size="sm"
                          onClick={() => {
                            window.location.href = `https://nightz.app/event/${eventId}`;
                          }}
                        >
                        {translate("productPage.redeem")}
                        </PrimaryButton>
                      </Box>
                    </Box>

                    {ticket.promotionType === "TIERED" && (
                      <Box
                        style={{
                          ...styles.avatarsAndProgressRow,
                          flexDirection: isMobile ? "column" : "row",
                          marginTop: isMobile ? 0 : 10,
                          gap: theme.spacing(3.75),
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: theme.spacing(1),
                          }}
                        >
                          <Image
                            src="/assets/avatars/avatar1.png"
                            sx={{ ...styles.image }}
                          />
                          <Image
                            src="/assets/avatars/avatar2.png"
                            sx={{ ...styles.image, marginLeft: -1 }}
                          />
                          <Image
                            src="/assets/avatars/avatar3.png"
                            sx={{ ...styles.image, marginLeft: -1 }}
                          />
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingHorizontal: 16,
                            justifyContent: "space-between",
                            gap: theme.spacing(3),
                          }}
                        >
                          <Box
                            style={{
                              width: "223px",
                              height: theme.spacing(6.25),
                              padding: 5,
                            }}
                          >
                            <Box
                              style={{
                                backgroundImage:
                                  "linear-gradient(to right, rgba(167, 167, 167, 0.3), white)",
                                borderRadius: "25px",
                                border: `solid 1px ${theme.palette.grey[500]}`,
                                height: "14px",
                                paddingTop: 10,
                              }}
                            />
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: theme.spacing(1),
                              }}
                            >
                              <Box
                                style={{
                                  color: theme.palette.grey[500],
                                }}
                              >
                                <Iconify icon="octicon:person-16" />
                              </Box>
                              {ticket.promotionMetadata
                                .slice()
                                .sort(
                                  (a, b) => a.nrParticipants - b.nrParticipants
                                )
                                .map((it, i) => (
                                  <Box
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      color:
                                        ticket.promotionMetadata.length !==
                                        i + 1
                                          ? theme.palette.grey[500]
                                          : undefined,
                                    }}
                                  >
                                    <Typography>{it.nrParticipants}</Typography>
                                    <Iconify icon={"octicon:person-16"} />
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                          <Box style={{}}>
                            <Typography
                              style={{
                                color: theme.palette.text.primary,
                                fontFamily: "Visby",
                                fontSize: theme.spacing(3),
                                fontWeight: "500",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                lineHeight: theme.spacing(3),
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: theme.spacing(3),
                                    fontWeight: "500",
                                    fontFamily: "Visby",
                                  }}
                                >
                                  {minPrice}
                                </span>
                                <span
                                  style={{
                                    fontSize: theme.spacing(2.5),
                                    fontFamily: "Visby",
                                  }}
                                >
                                  lei
                                </span>
                              </div>
                              <Typography
                                style={{
                                  color: theme.palette.text.primary,
                                  fontFamily: "Visby",
                                  fontSize: theme.spacing(2),
                                }}
                              >
                                /pers.
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              ))
        )}
      </>
    );
  };

  const checkCartValidityAndAdd = async (actionType) => {
    if (!getRoles().People.includes(user.role)) {
      enqueueSnackbar("A company can't buy tickets", {
        variant: "error",
      });
      return;
    }

    if (hasOtherEventItemsInCart) {
      setClearCartModalOpen(true);
      setActionType(actionType);
      return;
    }

    if (actionType === "buy") {
      await addToCart();
      navigate(PATHS.listings.checkout, { replace: true });
    }
    if (actionType === "add") {
      await addToCart();
    }
  };

  const addToCart = async () => {
    if (!getRoles().People.includes(user.role)) {
      enqueueSnackbar("A company can't buy tickets", {
        variant: "error",
      });
      return;
    }
    
    try {
      if (eventHasSeatingMap) {
        const selectedObject = await chart?.listSelectedObjects();
        const holdToken = chart?.holdToken;

        const formattedQuantity = selectedObject.reduce((acc, seat) => {
          const ticketCategory = ticketTypes.find(
            (ticket) => ticket.name === seat.category.label
          );

          if (!ticketCategory) {
            return acc;
          }

          const ticketId = ticketCategory.id;
          const existingItem = acc.find((item) => item.id === ticketId);

          if (existingItem) {
            existingItem.seatIds.push(seat.id);
            existingItem.quantity++;
          } else {
            acc.push({
              id: ticketId,
              quantity: 1,
              seatIds: [seat.id],
              seatsHoldToken: holdToken,
            });
          }

          return acc;
        }, []);

        await onAddCart(formattedQuantity, cloudQuantity);
      } else {
        await onAddCart(localQuantity, cloudQuantity);
      }
      await getCartData();

      if (eventChartKey) {
        setTicketFlowStep(0);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const acceptTicketDeleteAndAdd = async () => {
    await doClearCartItems();

    if (actionType === "buy") {
        await addToCart();
      navigate(PATHS.listings.checkout, { replace: true });
    }

    if (actionType === "add") {
      await addToCart();
    }

    setClearCartModalOpen(false);
  };

  const renderButtons = useMemo(() => {
    if (!eventHasSeatingMap) {
      return (
        <Stack
          direction={"row"}
          justifyContent={isMobile ? "center" : "flex-end"}
          sx={{
            mt: 3,
            mb: 5,
          }}
        >
          <SecondaryButton
            size="large"
            variant="contained"
            startIcon={<Iconify icon={"ic:round-add-shopping-cart"} />}
            onClick={async () => {
              if (!getRoles().People.includes(user.role)) {
                enqueueSnackbar("A company can't buy tickets", {
                  variant: "error",
                });
                return;
              }

              await checkCartValidityAndAdd("add");
              // await onAddCart(localQuantity, cloudQuantity);
              // await getCartData();
            }}
            sx={{
              // whiteSpace: "nowrap",
              mr: isMobile ? 0.75 : 1.5,
              textTransform: "none",
              px: isMobile ? MOBILE_BUY_BUTTON_PADDING : WEB_BUY_BUTTON_PADDING,
              width: isMobile ? MOBILE_BUY_BUTTON_WIDTH : WEB_BUY_BUTTON_WIDTH,
            }}
            disabled={!getTotalQty()}
          >
            {translate(
              showAlternativeButtonText
                ? "productPage.update"
                : "productPage.addToCart"
            )}
          </SecondaryButton>
          <PrimaryButton
            size="large"
            onClick={async () => {
              if (!getRoles().People.includes(user.role)) {
                enqueueSnackbar("A company can't buy tickets", {
                  variant: "error",
                });
                return;
              }

              await checkCartValidityAndAdd("buy");
            }}
            variant="contained"
            disabled={!getTotalQty()}
            sx={{
              textTransform: "none",
              px: isMobile ? MOBILE_BUY_BUTTON_PADDING : WEB_BUY_BUTTON_PADDING,
              width: isMobile ? MOBILE_BUY_BUTTON_WIDTH : WEB_BUY_BUTTON_WIDTH,
            }}
          >
            {`${translate("productPage.buyNow")} (${getTotalQty()})`}
          </PrimaryButton>
        </Stack>
      );
    }

    if (ticketFlowStep === 0) {
      return (
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          sx={{
            mt: 3,
            mb: 5,
          }}
        >
          <PrimaryButton
            size="large"
            onClick={() => {
              getNumberOfTicketsForSeatingMap();
              setTicketFlowStep(1);
            }}
            variant="contained"
            disabled={!getTotalQty()}
            sx={{
              textTransform: "none",
              px: isMobile ? MOBILE_BUY_BUTTON_PADDING : WEB_BUY_BUTTON_PADDING,
              width: isMobile ? MOBILE_BUY_BUTTON_WIDTH : WEB_BUY_BUTTON_WIDTH,
            }}
          >
            {`${translate("productPage.chooseSeats")} (${getTotalQty()})`}
          </PrimaryButton>
        </Stack>
      );
    }
    if (ticketFlowStep === 1) {
      return (
        <Stack
          direction={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "center" : "space-between"}
          sx={{
            mt: 3,
            mb: 5,
          }}
        >
          <PrimaryButton
            size="large"
            onClick={() => {
              setTicketFlowStep(0);
            }}
            variant="contained"
            sx={{
              textTransform: "none",
              px: isMobile ? MOBILE_BUY_BUTTON_PADDING : WEB_BUY_BUTTON_PADDING,
              width: isMobile ? MOBILE_BUY_BUTTON_WIDTH : WEB_BUY_BUTTON_WIDTH,
            }}
          >
            {`${translate("productPage.chooseTickets")}`}
          </PrimaryButton>
          <Box
            sx={{
              mt: isMobile ? 2 : 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <SecondaryButton
              size="large"
              variant="contained"
              startIcon={<Iconify icon={"ic:round-add-shopping-cart"} />}
              onClick={async () => {
                await checkCartValidityAndAdd("add");
              }}
              sx={{
                // whiteSpace: "nowrap",
                mx: isMobile ? 0.75 : 1.5,
                ml: isMobile ? 0 : 1.5,
                textTransform: "none",
                px: isMobile
                  ? MOBILE_BUY_BUTTON_PADDING
                  : WEB_BUY_BUTTON_PADDING,
                width: isMobile
                  ? MOBILE_BUY_BUTTON_WIDTH
                  : WEB_BUY_BUTTON_WIDTH,
              }}
              disabled={!getTotalQty() || !userSelectedAllSeats}
            >
              {translate(
                showAlternativeButtonText
                  ? "productPage.update"
                  : "productPage.addToCart"
              )}
            </SecondaryButton>
            <PrimaryButton
              size="large"
              onClick={async () => {
                await checkCartValidityAndAdd("buy");
              }}
              variant="contained"
              disabled={!getTotalQty() || !userSelectedAllSeats}
              sx={{
                textTransform: "none",
                px: isMobile
                  ? MOBILE_BUY_BUTTON_PADDING
                  : WEB_BUY_BUTTON_PADDING,
                width: isMobile
                  ? MOBILE_BUY_BUTTON_WIDTH
                  : WEB_BUY_BUTTON_WIDTH,
              }}
            >
              {`${translate("productPage.buyNow")} (${getTotalQty()})`}
            </PrimaryButton>
          </Box>
        </Stack>
      );
    }
  }, [
    eventHasSeatingMap,
    ticketFlowStep,
    isMobile,
    getTotalQty,
    translate,
    showAlternativeButtonText,
    user.role,
    checkCartValidityAndAdd,
    enqueueSnackbar,
    onAddCart,
    localQuantity,
    cloudQuantity,
    navigate,
    getNumberOfTicketsForSeatingMap,
    userSelectedAllSeats,
  ]);

  return (
    <>
      {eventHasSeatingMap ? (
        ticketFlowStep === 0 ? (
          <Step0Flow />
        ) : (
          <Step1Flow
            eventChartKey={eventChartKey}
            maxSelectableSeats={maxSelectableSeats}
            seatingMapPricing={seatingMapPricing}
            setChart={setChart}
            setUserSelectedAllSeats={setUserSelectedAllSeats}
          />
        )
      ) : (
        <Step0Flow />
      )}

      {isPastMaxValue && (
        <Alert sx={{ my: 2 }} severity="info">
          {translate("checkoutPage.cartMaxQuantity")}
        </Alert>
      )}

      {shouldRenderButtons && renderButtons}

      <DeleteForeignTicketsModal
        modalOpen={clearCartModalOpen}
        setModalOpen={setClearCartModalOpen}
        confirmDeleteCallback={acceptTicketDeleteAndAdd}
      />
    </>
  );
}

const styles = {
  rowBox: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 16,
    justifyContent: "space-between",
    alignItems: "center",
  },
  avatarsAndProgressRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingVertical: 8,
  },
  image: {
    width: 30,
    height: 30,
    borderRadius: 30,
  },
};
