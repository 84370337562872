import { gql } from "@apollo/client";

const GET_EVENTS = gql(`query Events {
  events {
    address {
      street
      city
      county
      venueName
      googleLocationLink
    }
    borderColor
    category {
      id
      name
      subcategories {
        id
        name
      }
    }
    company {
      approval
      avatar
      comission
      id
    }
    description
    documents {
      id
      key
      status
      title
    }
    endDate
    fields
    id
    images
    isMainEvent
    isPublished
    name
    organizerDisplayName
    organizerEmail
    organizerPhone
    organizerWebsite
    promoCodes {
      id
      name
      percent
    }
    startDate
    entryType
    status
    subcategory {
      id
      name
    }
    subCategory {
      id
      name
    }
    tags {
      id
      name
    }
    ticketTypes {
      id
      ticketOrder
      isPublished
      order
      name
      description
      series
      total
      available
      price
      eventId
      categoryId
      category {
        id
        name
      }
      sellStartDate
      sellEndDate
    }
    visibility
    comission
  }
}

`);

// todo solve all the references for this query
// todo re add documents and missing fields
const GET_EVENT_BY_ID = gql(`query Event($eventId: ID!) {
  event(id: $eventId) {
    address {
      city
      county
      googleLocationLink
      street
      venueName
      moduleStatus
    }
    tickets {
      borderColor
      comission
      promoCodes {
        id
        name
        percent
      }
      ticketTypes {
        available
        category {
          id
          name
        }
        categoryId
        description
        eventId
        id
        isPublished
        name
        order
        price
        sellEndDate
        sellStartDate
        series
        ticketOrder
        total
        promotionType
        promotionMetadata
      }
      entryType
      ditlReport
      moduleStatus
    }
    general {
      category {
        id
        name
      }
      description
      company {
        account {
          email
          id
          phone
        }
        avatar
        approval
        comission
        firstName
        id
        lastName
        companyInfo {
          vatObligations
        }
      }
      openingHours
      endDate
      fields
      id
      images
      isPublished
      mainEventCountyVisibility
      name
      organizerDisplayName
      organizerEmail
      organizerPhone
      organizerWebsite
      startDate
      status
      subCategory {
        id
        name
      }
      tags {
        id
        name
      }
      visibility
      countyVisibility
      isPermanent
      trendingBg
      seatsIoEventKey
    }
    rating {
      bookLink
      priceRangeEnd
      priceRangeStart
      program
      rating
      reviewsCount
      moduleStatus
    }
    booking {
      areas {
        id
        name
        price
        description
        tables{
          id
          name
        }
      }
      automaticConfirmation
      bookingDuration
      bookingInterval
      holdingFee
      id
      openingHours
      tables {
        area {
          id
          name
        }
        capacity
        price
        description
        id
        name
      }
      moduleStatus
      moduleStatusBookingMenu
      menus {
        id
        name
        description
        category
        price
        quantity
        quantityMetric
      }
    }
  }
}`);

const GET_EVENT_LIST = gql`
  query Events {
    events {
      id
      name
      status
      images
      ticketTypes {
        price
      }
    }
  }
`;
const GET_EVENT_LIST_EVENTMANAGER = gql`
  query General {
    events {
      general {
        category {
          id
          name
        }
        fields
        id
        images
        name
        isPublished
        startDate
        endDate
        status
        subCategory {
          id
          name
        }
      }
      address {
        city
        county
        googleLocationLink
        street
        venueName
      }
      ticketsSummary {
        total
        available
      }
    }
  }
`;
const GET_ALL_TAGS = gql`
  query Tags {
    tags {
      id
      name
    }
  }
`;

const GET_EVENT_FOR_INVITE = gql`
  query Event($eventId: ID!) {
    event(id: $eventId) {
      general {
        images
        name
        organizerDisplayName
        startDate
        endDate
        organizerEmail
      }
      address {
        venueName
      }
    }
  }
`;

const GET_EVENT = gql`
  query Event($eventId: ID!) {
    event(id: $eventId) {
      id
      images
      name
      description
      organizerEmail
      organizerPhone
      organizerWebsite
      isPublished
      fields
      ticketTypes {
        price
        id
        name
        available
        ticketOrder
        total
        isPublished
        series
        sellStartDate
        sellEndDate
        order
      }
    }
  }
`;

const GET_EVENT_DATA_FOR_TICKETS_TABLE = gql`
  query Event($eventId: ID!) {
    event(id: $eventId) {
      general {
        name
      }
    }
  }
`;

const GET_QUICK_EVENTS = gql`
  query EventsQuickSearch($statuses: [EventStatus!], $companyIds: [ID!]) {
    eventsQuickSearch(statuses: $statuses, companyIds: $companyIds) {
      totalCount
      items {
        id
        name
      }
    }
  }
`;

export {
  GET_EVENT_LIST,
  GET_EVENTS,
  GET_EVENT_BY_ID,
  GET_ALL_TAGS,
  GET_EVENT_LIST_EVENTMANAGER,
  GET_EVENT,
  GET_EVENT_FOR_INVITE,
  GET_EVENT_DATA_FOR_TICKETS_TABLE,
  GET_QUICK_EVENTS,
};
