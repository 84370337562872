import { gql } from "@apollo/client";

const VAT_OBLIGATIONS = gql`
  query CompanyInfo {
    me {
      ... on Account {
        profile {
          ... on CompanyProfile {
            companyInfo {
              vatObligations
            }
          }
        }
      }
    }
  }
`;

const GET_USER = gql`
  query Me {
    me {
      ... on Account {
        accountType
        email
        id
        phone
        profile {
          ... on UserProfile {
            account {
              accountType
              email
              id
              phone
            }
            avatar
            enableGeoTracking
            firstName
            id
            isDeleted
            lastName
            username
          }
          ... on CompanyProfile {
            account {
              accountType
              email
              id
              phone
            }
            approval
            avatar
            comission
            companyInfo {
              address {
                number
                city
                county
                googleLocationLink
                street
                venueName
              }
              bankName
              city
              companyName
              companyType
              county
              currency
              displayName
              documents {
                title
                url
              }
              euVatNumber
              europeanUniqueIdentifier
              iban
              id
              invoiceSeries
              legalRepresentativeEmail
              legalRepresentativeFirstName
              legalRepresentativeLastName
              legalRepresentativePhone
              postalCode
              registrationCountry
              swiftCode
              tradeRegistryNumber
              uniqueRegistrationCode
              vatObligations
            }
            firstName
            id
            lastName
          }
          ... on AdminProfile {
            id
            name
          }
        }
      }
      ... on ShadowAccount {
        accountType
        id
        profile {
          account {
            accountType
            email
            id
            phone
          }
          avatar
          enableGeoTracking
          firstName
          id
          isDeleted
          lastName
          username
        }
      }
    }
  }
`;

const GET_SHADOW_USER = gql`
  query Me {
    me {
      ... on ShadowAccount {
        id
        accountType
        profile {
          id
          isDeleted
          firstName
          lastName
          username
          avatar
          enableGeoTracking
        }
      }
    }
  }
`;

const GET_COMPANY_USER_STATUS = gql`
  query CompanyProfile {
    me {
      ... on Account {
        profile {
          ... on CompanyProfile {
            approval
          }
        }
      }
    }
  }
`;

export { GET_USER, VAT_OBLIGATIONS, GET_SHADOW_USER, GET_COMPANY_USER_STATUS };
