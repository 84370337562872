import { SeatsioSeatingChart } from "@seatsio/seatsio-react";
import { useLocales } from "src/locales";

export const SeatsChart = ({
  eventChartKey,
  seatingMapPricing,
  onChartRendered,
  maxSelectableSeats,
  setUserSelectedAllSeats,
}) => {
  const minSelectableSeats = maxSelectableSeats?.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const {
    currentLang: { value },
  } = useLocales();

  return (
    <SeatsioSeatingChart
      workspaceKey="c1c2363c-4285-4790-82e8-f1b6e04c5099"
      region="eu"
      event={eventChartKey}
      pricing={seatingMapPricing}
      onChartRendered={onChartRendered}
      language={value || "en"}
      maxSelectedObjects={maxSelectableSeats}
      showFullScreenButton={false}
      loading="Loading seating chart..."
      mode="normal"
      session="continue"
      showLegend
      priceFormatter={() => ``}
      selectionValidators={[
        {
          type: "minimumSelectedPlaces",
          minimum: minSelectableSeats,
        },
      ]}
      onSelectionValid={() => {
        setUserSelectedAllSeats(true);
      }}
      onSelectionInvalid={() => {
        setUserSelectedAllSeats(false);
      }}
    />
  );
};
