import { Helmet } from "react-helmet-async";
import logo from "src/assets/nightz-icon-with-bg.jpg";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useAuthContext } from "src/hooks/useAuthContext";
import { Navigate, useNavigate } from "react-router";
import { PATH_AUTH } from "src/routes/paths";
import { useSnackbar } from "notistack";
import { useLocales } from "src/locales";
import { useIsMaintenance } from "src/hooks/useIsMaintenance";

export const Maintenance = () => {
  const { isAuthenticated, logout } = useAuthContext();
  const {isWebMaintenance,loading} = useIsMaintenance() ;
  const navigate = useNavigate();
  const enqueueSnackbar = useSnackbar();
  const { translate } = useLocales();

  if(!isWebMaintenance && !loading){
    return <Navigate to='/'/>
  }

  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <>
      <Helmet>
        <title>{translate('maintenance.underMaintenance')}</title>
      </Helmet>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack gap={10} alignItems="center">
          <Box
            sx={{
              width: 200,
              height: 200,
              display: "flex",
              alignItems: "center",
              borderRadius: 4,
            }}
          >
            <img src={logo} alt={logo} style={{ borderRadius: "16px" }} />
          </Box>
          <Stack sx={{ textAlign: "center", width: 350, gap: 4 }}>
            <Typography variant="h3">Maintenance work</Typography>
            <Typography>
              {translate('maintenance.backSoon')}
            </Typography>
            {!isAuthenticated ? (
              <Button
                onClick={() => {
                  navigate(PATH_AUTH.login, { replace: true });
                }}
              >
                {translate('login.submit')}
              </Button>
            ) : (
              <Button onClick={handleLogout}>{translate('accountMenu.logout')}</Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
